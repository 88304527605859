<template>
<div>
      <v-snackbar
      v-model="isSnackbarCenteredVisible"
      :light="$vuetify.theme.dark"
      centered
      :color="errorColor"
      timeout="3000"
    >
      {{snackbarMessage}}
    </v-snackbar>
  <!-- Indivdual Day Video Page -->
    <v-row>
      <v-col
      cols="12"
      >
      <p class="text-2xl">
         <v-btn
            icon
            small
            color="primary"
            :to="`/marathon/${marathonId}`"
          >
            <v-icon>
              {{ icons.mdiArrowLeftCircle }}
            </v-icon>
          </v-btn>  
       День {{dayNumber}}
      </p>
      <p>
        {{daySecondName}}
      </p>
    </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="shoppingList"
          color="primary"
          class="ms-3 mb-3"
          :href="`${shoppingList}`"
           target="_blank"
        >
          <v-icon>
          {{ icons.mdiCartArrowDown }}
          </v-icon>
          <span class="ms-3">Продуктовый список</span>
        </v-btn>
         <v-btn
         v-if="menuUrl"
          color="primary"
          class="ms-3"
          :href="`${menuUrl}`"
           target="_blank"
        >
          <v-icon>
          {{ icons.mdiFoodApple }}
          </v-icon>
          <span class="ms-3"> Скачать сегодняшнее меню</span>
        </v-btn>
      </div>
        </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <v-card class="overflow-hidden">
              <v-card-title class="align-start">
                Разогрев
              </v-card-title>
              <v-card-text>
              <video-player 
              class="video-player-box"
              oncontextmenu="return false;"
              ref="videoPlayer"
              :options="playerOptionsWarmup"
              :playsinline="true"
              customEventName="customstatechangedeventname"
              >
              </video-player>
        </v-card-text>
        </v-card>
      </v-col>
      <v-col
              cols="12"
        md="6"
        sm="12"
      >
        <v-card class="overflow-hidden">
          <v-card-title class="align-start">
            Тренировка
          </v-card-title>
          <v-card-text>
          <video-player 
          oncontextmenu="return false;"
          class="video-player-box"
          ref="videoPlayer"
          :options="playerOptionsWorkout"
          :playsinline="true"
          customEventName="customstatechangedeventname"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @waiting="onPlayerWaiting($event)"
          @playing="onPlayerPlaying($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied">
          </video-player>           
        </v-card-text>
        </v-card>
      </v-col>
  </v-row>
  <div class="d-flex flex-column align-center justify-center mt-15">
   <!-- <v-checkbox
      v-model="completedDay"
      @change="updateDayCompleted($event)"
      :label="`Выполнено?`"
      :on-icon="icons.mdiCheckCircle"
      color="success"
      hide-details
    ></v-checkbox> -->
  <span class="mr-3">Выполнила тренировку?</span>
  <v-radio-group
    v-model="isDayCompleted"
    class="mt-0"
    hide-details
    row
  >
    <v-radio
      label="Да"
      :value=true
      :off-icon="icons.mdiCloseCircle"
      :on-icon="icons.mdiCheckCircle"
    ></v-radio>
    <v-radio
      label="Нет"
      :value=false
      :off-icon="icons.mdiCloseCircle"
      :on-icon="icons.mdiCheckCircle"
    ></v-radio>
  </v-radio-group>
  <span class="mr-3 mt-10">Как было тренироваться?</span>
  <v-radio-group
    v-model="isDayDifficult"
    class="mt-0"
    hide-details
    row
  >
    <v-radio
      label="Тяжело"
      :value=true
      :off-icon="icons.mdiCloseCircle"
      :on-icon="icons.mdiCheckCircle"
    ></v-radio>
    <v-radio
      label="Легко" 
      :value=false
      :off-icon="icons.mdiCloseCircle"
      :on-icon="icons.mdiCheckCircle"
    ></v-radio>
  </v-radio-group>
  <v-btn
  color="primary"
  outlined
  @click="updateDayCompleted()"
  class="mt-9"
  >
    Сохранять
  </v-btn>
</div>
</div>
</template>
<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { mapGetters } from 'vuex'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import store from '@/store'

import {
  mdiArrowLeftCircle,
  mdiCheckCircle,
  mdiFoodApple,
  mdiCartArrowDown,
} from '@mdi/js'

export default {
  metaInfo() {
    return {
      title: `${this.$route.params.marathon_id} Day ${this.dayNumber}`
    };
  },
  components: {
    StripeCheckout,
    StatisticsCardWithImages,
    videoPlayer,
  },
  data () {
    return {
      isSnackbarCenteredVisible: false,
      snackbarMessage: null,
      errorMessages: [],
      errorColor:'error',
      imageUrl: '',
      menuUrl: false,
      shoppingList: false,
      playerOptionsWarmup: {
        // videojs options
        muted: true,
        language: 'en',
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
      },
      playerOptionsWorkout: {
        // videojs options
        muted: true,
        language: 'en',
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }]
      },
      isDayCompleted: '',
      isDayDifficult: '',
      loading: false,
      icons: {
        mdiArrowLeftCircle,
        mdiCheckCircle,
        mdiFoodApple,
        mdiCartArrowDown
      },
    };
  },
  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    player() {
      return this.$refs.videoPlayer.player
    },
    dayName() {
      return this.$route.params.name
    },
    daySecondName() {
      return this.$route.params.secondName
    },
    dayNumber() {
      return parseInt(this.$route.params.day_id)
    },
    marathonId() {
      return this.$route.params.marathon_id
    },
  },
  watch: {
  'user': {
    handler (newVal) {
      if (newVal) { // check if userid is available
        store.dispatch('getDayProgress',{
          marathonId: this.marathonId,
        })
        .then((response) => {
          if(response[this.dayNumber]) {
            this.isDayCompleted = response[this.dayNumber].isDayCompleted
            this.isDayDifficult = response[this.dayNumber].isDayDifficult
          }
        })
      }
    },
  }
},
  mounted() {
    store.dispatch('getDayProgress',{
      marathonId: this.marathonId,
    })
    .then((response) => {
      if(response[this.dayNumber]) {
        this.isDayCompleted = response[this.dayNumber].isDayCompleted
        this.isDayDifficult = response[this.dayNumber].isDayDifficult
      }
    })
    store.dispatch('getDayVideos',{
      marathonId: this.$route.params.marathon_id,
      dayNumber: parseInt(this.$route.params.day_id)
    })
    .then((response) => {
      if(response.warmupVideoImage) {
        this.playerOptionsWarmup.poster = response.warmupVideoImage;
      }
      if(response.workoutVideoImage) {
        this.playerOptionsWorkout.poster = response.workoutVideoImage;
      }
      this.playerOptionsWarmup.sources[0].src = response.warmupVideoUrl;
      this.playerOptionsWorkout.sources[0].src = response.workoutVideoUrl;
      this.menuUrl = response.menuUrl;
      this.shoppingList = response.shoppingList;
    })
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerEnded(player) {
      // console.log('the player is ended', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerWaiting(player) {
      // console.log('the player is waiting', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerPlaying(player) {
      // console.log('the player is playing', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerTimeupdate(player) {
      // console.log('the player is time is updated', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerLoadeddata(player) {
      // console.log('the player is time is loaded', player)
      // you can use it to do something...
      // player.[methods]
    },
    updateDayCompleted() {
      if(this.isDayCompleted === '' || this.isDayDifficult === '') {
          // errorColor.value = 'success';
          this.snackbarMessage = 'Пожалуйста, ответьте на все вопросы';
          this.isSnackbarCenteredVisible = true;
      } else {
        store.dispatch('setDayCompleted',{
          marathonId: this.marathonId,
          dayId: this.dayNumber,
          isDayCompleted: this.isDayCompleted,
          isDayDifficult: this.isDayDifficult,
        })
        .then(() => {
          this.errorColor = 'success';
          this.snackbarMessage = 'Обновлено';
          this.isSnackbarCenteredVisible = true;
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.vjs-poster {
  background-color: #fff !important
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
